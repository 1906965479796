<template>
     <div class="mt-3" ref="preguntasDiv">
         <v-row align="center" justify="center" v-if="this.$route.params.show_logo">
             <LogoDI></LogoDI>
        </v-row>

        <BackHeader title="Preguntas Frecuentes" :onBack="volver"></BackHeader>

        <v-card class="ma-5">
            <v-col align="justify">
                <div class="ma-5">
                    <p style="max-width: 70vw;" >
                        Bienvenidos a nuestra sección de <strong>Preguntas Frecuentes</strong> sobre el proceso de prácticas en el Departamento de Informática de la Universidad Técnica Federico Santa María. Aquí encontrarás respuestas a las consultas más comunes, desde la diferenciación de ofertas de práctica hasta detalles sobre la finalización y evaluación de estas.
                    </p>
                    <p style="max-width: 70vw;" >
                        Si tienes preguntas adicionales, contáctanos a través del correo de los encargados o del ayudante de prácticas (Preferir consultar al Ayudante).                    
                    </p>
                    </div>
                <v-row class="mx-3">
                    <v-col class="text-center" >
                        <h4>Profesor Encargado Jose Luis Marti</h4>
                        <h4>jmarti@inf.utfsm.cl</h4>
                    </v-col>
                    <v-col class="text-center" >
                        <h4>Profesor Encargado Pedro Godoy</h4>
                        <h4>pgodoy@inf.utfsm.cl</h4>
                    </v-col>
                    <v-col class="text-center" >
                        <h4>Encargado Jose González Pasten</h4>
                        <h4>jose.gonzalezpa@usm.cl</h4>
                    </v-col>
                    <v-col class="text-center">
                        <h4>Ayudante de prácticas</h4>
                        <h4>ayud-pra@inf.utfsm.cl </h4>
                    </v-col>
                </v-row>
            </v-col>
        </v-card>
        <div class="ma-5">
            <div>
                <v-expansion-panels multiple >
                    <v-expansion-panel v-for="(values) in data_preguntas">
                        <v-expansion-panel-header> <h2>{{values.nombre}}</h2> </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-expansion-panels accordion  multiple>
                                <v-expansion-panel v-for="(pregunta) in values.preguntas" @change="toggleActive(pregunta.numero)">
                                    <v-expansion-panel-header>  <h3 :class="{ active: isActive[pregunta.numero] }">{{pregunta.numero}}. {{pregunta.pregunta}}</h3></v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <div class="my-3 mx-16" v-html="'<strong>R: </strong>' + pregunta.respuesta"></div>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-expansion-panels>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
            </v-expansion-panels>
            </div>
        </div>

    </div>

</template>

<script>
import LogoDI from "@/components/icons/logoDI";
import axios from 'axios';
import BackHeader from "@/components/content/BackHeader";

export default {
    components: {LogoDI,BackHeader},

    data() {
        return {
            data_preguntas:[],
            numero:0,
            isActive: [],
        };
    },
    created(){
        axios({
            url: `${process.env.VUE_APP_API_URL}/seccionpregunta`,
            method: "GET",
        }).then((response) => {
            // this.data_preguntas = response.data;
            let numero = 1;
            this.data_preguntas = response.data.map(seccion => {
                seccion.preguntas = seccion.preguntas.map(pregunta => {
                pregunta.numero = numero++;
                return pregunta;
            });
            return seccion;
            });
        });
    },

    methods:{
        volver() {
            if (this.$route.params.back_info){
                this.$router.push(this.$route.params.back_info);
            } else
            this.$router.push("/");
        },
        toggleActive(index,) {
            // var old_value = this.isActive[index];
            // this.isActive.fill(false);
            // this.$set(this.isActive, index, old_value);
            this.$set(this.isActive, index, !this.isActive[index]);
        },
        
    }
};
</script>

<style>
.active {
  color: #E65100;
}
</style>
